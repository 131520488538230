import React, { useEffect, useState } from "react";
import Info from "../assets/icons/info.svg";
import DoubleArrow from "../assets/icons/chevron-right-double.svg";
import Cancel from "../assets/icons/wrong_circle.svg";
import Arrow from "../assets/icons/chevron-right.svg";
import { useTranslation } from "react-i18next";
import ConnectWallet from "./ConnectWallet";
import { useStore } from "store";
import close from "../assets/icons/close.svg";
import { Stake as kanaStake } from "sui-stake";
import {
  getAllSuiTokenBalance,
  getSuiStructuredRoutes,
  getUiAmount,
  preventPasteNegativeNumber,
} from "utils/hepler";
import { useWalletKit } from "@mysten/wallet-kit";
import { useImmer } from "use-immer";
import {
  SUI_TOKEN_1,
  SUI_TOKEN_2,
  SUI_TOKEN_3,
  SUI_TOKEN_4,
} from "utils/defaultTokens";
import suiImg from "../assets/icons/sui.svg";
import Lottie from "react-lottie-player";
import kanaloaderred from "../assets/Kana loader.json";
import { Stake as KanaSui } from "sui-stake";
import {
  submitFaucetPayload,
  submitSuiPayload,
  submitUnStakeSuiPayload,
} from "utils/sign";
import {
  JsonRpcProvider,
  testnetConnection,
} from "@mysten/sui.js";
import { Button, Dropdown, Tooltip, notification } from "antd";
import useDebounce from "hooks/useDebounce";
const Stake = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [api,contextHolder] = notification.useNotification();
  const [isRouteLoading, setRouteLoading] = useState(false);
  const [suiTokenList, setSuiTokenList] = useState<any>([]);
  const [suiUnstakeTokenList, setSuiUnstakeTokenList] = useState<any>([]);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  console.log(
    "🚀 ~ file: Stake.tsx:52 ~ Stake ~ availableRoutes:",
    availableRoutes
  );
  const [unStakeavailableRoutes, setUnStakeAvailableRoutes] = useState(0);
  const [selectedRoute, setSelectedRoute] = useState<any>(null);
  const {
    currentAccount,
    isConnected,
    signAndExecuteTransactionBlock,
  } = useWalletKit();
  const [sourceInfo, setSourceInfo] = useImmer<any>({
    chainImg: suiImg,
    tokenSymbol: SUI_TOKEN_1.symbol,
    tokenImg: SUI_TOKEN_1.logoURI,
    tokenAmount: "",
    tokenAddress: SUI_TOKEN_1.address,
    tokenBalance: "",
  });
  const [unStakesourceInfo, setUnstakeSourceInfo] = useImmer<any>({
    chainImg: suiImg,
    tokenSymbol: SUI_TOKEN_4.symbol,
    tokenImg: SUI_TOKEN_4.logoURI,
    tokenAmount: "",
    tokenAddress: SUI_TOKEN_4.address,
    tokenBalance: "",
  });
  const {
    updateIsWalletConnectVisible,
    isWalletConnectVisible,
    isTokenListVisible,
    updateIsTokenListVisible,
    isUnstakeTokenListVisible,
    updateIsUnstakeTokenListVisible,
    activeItem,
  } = useStore();

  useEffect(() => {
    const stake = new kanaStake();
    const token = stake.tokenList();
    const unStaketoken = stake.unStakeTokenList();
    setSuiTokenList(token);
    setSuiUnstakeTokenList(unStaketoken);
  }, []);
  const fetchTokenPrice = async () => {
    const token = await getAllSuiTokenBalance(
      currentAccount?.address,
      suiTokenList
    );
    const unStaketoken = await getAllSuiTokenBalance(
      currentAccount?.address,
      suiUnstakeTokenList
    );
    if (token?.success) {
      setSuiTokenList(token.data);
      const updatedtoken = token.data?.find(
        (x: any) =>
          String(x.address)?.toLowerCase() ===
          String(sourceInfo?.tokenAddress)?.toLowerCase()
      );

      setSourceInfo((source: any) => {
        source.tokenSymbol = updatedtoken.symbol;
        source.tokenImg = updatedtoken.uri;
        source.tokenAddress = updatedtoken.address;
        source.tokenBalance = updatedtoken.balance;
      });
    }
    if (unStaketoken?.success) {
      setSuiUnstakeTokenList(unStaketoken.data);
      const updatedtoken = unStaketoken.data?.find(
        (x: any) =>
          String(x.address)?.toLowerCase() ===
          String(unStakesourceInfo?.tokenAddress)?.toLowerCase()
      );

      setUnstakeSourceInfo((source: any) => {
        source.tokenSymbol = updatedtoken.symbol;
        source.tokenImg = updatedtoken.uri;
        source.tokenAddress = updatedtoken.address;
        source.tokenBalance = updatedtoken.balance;
      });
    }
  };
  useEffect(() => {
    if (isConnected) {
      if (
        currentAccount?.chains[0] !== "sui:testnet" &&
        currentAccount?.chains[0] !== undefined
      ) {
        notification.error({
          message: `Please Switch To Testnet`,
        });
      }
      const fetchPrice = async () => {
        await fetchTokenPrice();
      }
      fetchPrice()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  const { t } = useTranslation();
  const updateTokenSelect = (token: any) => {
    try {
      if (!(token.address === sourceInfo.tokenAddress)) {
        setSourceInfo((source: any) => {
          source.tokenSymbol = token.symbol;
          source.tokenImg = token.uri;
          source.tokenAddress = token.address;
          source.tokenBalance = token?.balance
            ? (token?.balance).toFixed(2)
            : 0;
        });
      }
      updateIsTokenListVisible(false);
    } catch (error: any) {
      console.log("Error occurred in updateTokenSelect::" + error);
    }
  };
  const updateUnstakeTokenSelect = (token: any) => {
    try {
      if (!(token.address === unStakesourceInfo.tokenAddress)) {
        setUnstakeSourceInfo((source: any) => {
          source.tokenSymbol = token.symbol;
          source.tokenImg = token.uri;
          source.tokenAddress = token.address;
          source.tokenBalance = token?.balance
            ? (token?.balance).toFixed(2)
            : 0;
        });
      }
      updateIsUnstakeTokenListVisible(false);
    } catch (error: any) {
      console.log("Error occurred in updateUnstakeTokenSelect::" + error);
    }
  };
  const clearAmount = (percentage: any) => {
    if (!isRouteLoading) {
      let amount = "";
      if (percentage === "Clear") {
        setSourceInfo((source: any) => {
          source.tokenAmount = amount.toString();
        });
      }
    }
  };
  const unStakeClearAmount = (percentage: any) => {
    if (!isRouteLoading) {
      let amount = "";
      if (percentage === "Clear") {
        setUnstakeSourceInfo((source: any) => {
          source.tokenAmount = amount.toString();
        });
      }
    }
  };
  const onTokenAmountChange = (e: any) => {
    let value: any = e.target.value;
    // only accept digit . and numbers
    if (
      // eslint-disable-next-line no-useless-escape
      /^[0-9\.]*$/gm.test(value) &&
      // eslint-disable-next-line no-useless-escape
      ((value.match(/[\.,]/gm) &&
        value.match(/^[0-9]{0,9}(\.|,)?[0-9]{0,8}$/gm)) ||
        // eslint-disable-next-line no-useless-escape
        (!value.match(/[\.,]/gm) &&
          value.match(/^[0-9]{0,9}$/gm) &&
          (!value.match(/\./gm) || value.match(/\./gm)?.length <= 1) &&
          (!value.match(/,/gm) || value.match(/,/gm)?.length <= 1)))
    ) {
      // replace duplication if needed
      const amount = value
        .replace(/\.+/gm, ".")
        .replace(/,+/gm, ",")
        .replace(/^0+/gm, "0")
        // if first character is . then replace them with 0.
        .replace(/^\./, "0.");
      setSourceInfo((source: any) => {
        source.tokenAmount = amount;
      });
      setRouteLoading(true);
      if (Number(amount) <= 0) {
        setRouteLoading(false);
      }
    }
  };
  const unStakeonTokenAmountChange = (e: any) => {
    let value: any = e.target.value;
    // only accept digit . and numbers
    if (
      // eslint-disable-next-line no-useless-escape
      /^[0-9\.]*$/gm.test(value) &&
      // eslint-disable-next-line no-useless-escape
      ((value.match(/[\.,]/gm) &&
        value.match(/^[0-9]{0,9}(\.|,)?[0-9]{0,8}$/gm)) ||
        // eslint-disable-next-line no-useless-escape
        (!value.match(/[\.,]/gm) &&
          value.match(/^[0-9]{0,9}$/gm) &&
          (!value.match(/\./gm) || value.match(/\./gm)?.length <= 1) &&
          (!value.match(/,/gm) || value.match(/,/gm)?.length <= 1)))
    ) {
      // replace duplication if needed
      const amount = value
        .replace(/\.+/gm, ".")
        .replace(/,+/gm, ",")
        .replace(/^0+/gm, "0")
        // if first character is . then replace them with 0.
        .replace(/^\./, "0.");
      setUnstakeSourceInfo((source: any) => {
        source.tokenAmount = amount;
      });
      setRouteLoading(true);

      if (Number(amount) <= 0) {
        setRouteLoading(false);
      }
    }
  };
  let debounceAmount = useDebounce<string>(sourceInfo.tokenAmount, 1500);
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (debounceAmount != sourceInfo.tokenAmount) return;
    // eslint-disable-next-line eqeqeq
    if (sourceInfo.tokenAmount && Number(sourceInfo.tokenAmount) != 0) {
      getRoutes();
    } else {
      setRouteLoading(false);
      setAvailableRoutes([]);
    }
    // setAutoRefresh(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceInfo.tokenAmount, debounceAmount]);
  let debounceUnstakeAmount = useDebounce<string>(
    unStakesourceInfo.tokenAmount,
    1500
  );
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (debounceUnstakeAmount != unStakesourceInfo.tokenAmount) return;
    if (
      unStakesourceInfo.tokenAmount &&
      // eslint-disable-next-line eqeqeq
      Number(unStakesourceInfo.tokenAmount) != 0
    ) {
      getUnStakeRoutes();
    } else {
      setRouteLoading(false);
    }
    // setAutoRefresh(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unStakesourceInfo.tokenAmount, debounceUnstakeAmount]);
  const getUnStakeRoutes = async () => {
    try {
      const stake = new KanaSui();
      let tokenDecimalAmount = 0;
      const decimal = suiUnstakeTokenList?.find(
        (x: any) =>
          String(x.address)?.toLowerCase() ===
          String(unStakesourceInfo?.tokenAddress)?.toLowerCase()
      )?.decimal;
      tokenDecimalAmount =
        Number(unStakesourceInfo.tokenAmount) * 10 ** decimal;
      const quote = await stake.getUnstakeAmount(
        unStakesourceInfo.tokenAddress,
        tokenDecimalAmount
      );
      setUnStakeAvailableRoutes(
        Number(getUiAmount(Number(quote!), decimal).toFixed(2))
      );
    } catch (error: any) {
      if (
        error
          .toString()
          .includes("more than 2 sui is needed to stake but got") ||
        error.toString().includes("more than 2 sui  is needed to stake but got")
      ) {
        notification.warning({
          message: `Please Add Sufficient Amount To Stake`,
          description: `Minimum Required Two Sui To Stake Successfully. Please Enter More than ${sourceInfo.tokenSymbol === "SUI"
            ? 2
            : sourceInfo.tokenSymbol === "USDC"
              ? 3000
              : sourceInfo.tokenSymbol === "USDT"
                ? 3000
                : 0
            }  ${sourceInfo.tokenSymbol}`,
        });
      } else if (error.toString().includes("swap route not available")) {
        notification.warning({
          message: `Stake Route Not Available`,
        });
      } else {
        console.log("getRoutes ~ error:", error);
      }
    } finally {
      setRouteLoading(false);
    }
  };
  const getRoutes = async () => {
    try {
      const stake = new KanaSui();
      let tokenDecimalAmount = 0;
      const decimal = suiTokenList?.find(
        (x: any) =>
          String(x.address)?.toLowerCase() ===
          String(sourceInfo?.tokenAddress)?.toLowerCase()
      )?.decimal;
      tokenDecimalAmount = Number(sourceInfo.tokenAmount) * 10 ** decimal;
      const quote = await stake.stakeQuote(
        sourceInfo.tokenAddress,
        tokenDecimalAmount
      );
      console.log("🚀 ~ file: Stake.tsx:357 ~ getRoutes ~ quote:", quote);
      const data = await getSuiStructuredRoutes(quote, suiTokenList);
      setSelectedRoute(data[0]);
      let visibleRoutes = data?.slice(0, 4);
      setAvailableRoutes(visibleRoutes);
    } catch (error: any) {
      if (
        error
          .toString()
          .includes("more than 2 sui is needed to stake but got") ||
        error.toString().includes("more than 2 sui  is needed to stake but got")
      ) {
        notification.warning({
          message: `Please Add Sufficient Amount To Stake`,
          description: `Minimum Required Two Sui To Stake Successfully. Please Enter More than ${sourceInfo.tokenSymbol === "SUI"
            ? 2
            : sourceInfo.tokenSymbol === "USDC"
              ? 3000
              : sourceInfo.tokenSymbol === "USDT"
                ? 3000
                : 0
            }  ${sourceInfo.tokenSymbol}`,
        });
      } else if (error.toString().includes("swap route not available")) {
        notification.warning({
          message: `Stake Route Not Available`,
        });
      } else {
        console.log("getRoutes ~ error:", error);
      }
    } finally {
      setRouteLoading(false);
    }
  };
  const submitSwap = async () => {
    try {
      if (Number(sourceInfo.tokenAmount) >= Number(sourceInfo.tokenBalance)) {
        notification.warning({
          message: `Please add ${sourceInfo.tokenSymbol} to your wallet.`,
        });
      } else {
        const stake = new KanaSui();
        const suiProvider: any = new JsonRpcProvider(testnetConnection);
        const transactions = await stake.getStakeTransaction(
          selectedRoute.route,
          currentAccount?.address!,
          suiProvider
        );
        // const tx:any = await mintIusdt();
        const data = await submitSuiPayload(
          transactions,
          signAndExecuteTransactionBlock
        );
        await fetchTokenPrice();
        notification.success({
          message: `Stake successfully.`,
          description: (
            <div>
              <p>
                {data.length === 2 ? (
                  <a
                    className="text-purple-700 underline hover:underline hover:text-purple-500"
                    href={`https://suiexplorer.com/txblock/${data[1]?.digest}?network=testnet`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View transaction
                  </a>
                ) : (
                  <a
                    className="text-purple-700 underline hover:underline hover:text-purple-500"
                    href={`https://suiexplorer.com/txblock/${data[0]?.digest}?network=testnet`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View transaction
                  </a>
                )}
              </p>
            </div>
          ),
        });
      }
    } catch (error: any) {
      if (
        error.toString().includes("Error: Rejected from user") ||
        error.toString().includes("Error: User Rejected the request")
      ) {
        notification.warning({
          message: `Wallet Error`,
          description: `User Rejected The Request `,
        });
      } else {
        console.log("Stake.tsx:201 ~ submitSwap ~ error:", error);
      }
    }
  };
  const submitUnstake = async () => {
    try {
      if (
        Number(unStakesourceInfo.tokenAmount) >=
        Number(unStakesourceInfo.tokenBalance)
      ) {
        notification.warning({
          message: `Please add ${unStakesourceInfo.tokenSymbol} to your wallet.`,
        });
      } else {
        const stake = new KanaSui();
        const suiProvider: any = new JsonRpcProvider(testnetConnection);
        const decimal = suiTokenList?.find(
          (x: any) =>
            String(x.address)?.toLowerCase() ===
            String(unStakesourceInfo?.tokenAddress)?.toLowerCase()
        )?.decimal;
        let transactions: any = await stake.unstake(
          unStakesourceInfo.tokenAddress,
          Number(unStakesourceInfo.tokenAmount) * 10 ** decimal,
          currentAccount?.address!,
          suiProvider
        );
        transactions.setGasBudget(20000000);
        const data = await submitUnStakeSuiPayload(
          transactions,
          signAndExecuteTransactionBlock
        );
        await fetchTokenPrice();
        notification.success({
          message: `UnStake successfully.`,
          description: (
            <div>
              <p>
                {data.length === 2 ? (
                  <a
                    className="text-purple-700 underline hover:underline hover:text-purple-500"
                    href={`https://suiexplorer.com/txblock/${data?.digest}?network=testnet`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View transaction
                  </a>
                ) : (
                  <a
                    className="text-purple-700 underline hover:underline hover:text-purple-500"
                    href={`https://suiexplorer.com/txblock/${data[0]?.digest}?network=testnet`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View transaction
                  </a>
                )}
              </p>
            </div>
          ),
        });
      }
    } catch (error: any) {
      if (
        error.toString().includes("Error: Rejected from user") ||
        error.toString().includes("Error: User Rejected the request")
      ) {
        notification.warning({
          message: `Wallet Error`,
          description: `User Rejected The Request `,
        });
      } else {
        console.log("Stake.tsx:201 ~ submitSwap ~ error:", error);
      }
    }
  };
  const ChainAndTokenListView = () => {
    return (
      <div className="fixed inset-0  backdrop-blur-xl flex justify-center items-center bg-[rgba(0,0,0,0.3)] h-[100%] w-[100%] overflow-y-auto">
        <div
          id="wrapper"
          className=" font-inter flex flex-col  text-center  p-[32px]  rounded-[2.25rem] justify-center align-middle w-[34rem] h-auto   bg-[rgba(255,255,255,0.06)]  backdrop-blur-[36px] shadow-[0rem_2.5rem_2rem_-3.313rem_rgba(0,0,0,0.06)]   mt-[5.48rem]  border-[0.125rem] border-[rgba(255,255,255,0.2)] "
        >
          <div className="flex flex-row justify-between align-middle">
            <div className="text-white font-inter text-2xl font-bold">
              Stake From
            </div>
            <img
              className="cursor-pointer"
              src={close}
              alt="close"
              onClick={() => {
                updateIsTokenListVisible(false);
              }}
            />
          </div>
          <div className="flex flex-row justify-start align-middle py-3"></div>
          <div className="w-[100%] h-[288px] bg-[rgba(255,255,255,0.05)] rounded-3xl overflow-y-auto no-scrollbar  mt-2 ">
            {suiTokenList &&
              suiTokenList?.map((item: any, key: any) => {
                let dollarPrice = 0;
                dollarPrice = Number.isNaN(dollarPrice) || 0 ? 0 : dollarPrice;
                return (
                  <div
                    key={key}
                    onClick={() => updateTokenSelect(item)}
                    className={`${item.symbol === "HSUI" || item.symbol === "ANKRSUI"
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                      } flex flex-row justify-between align-middle items-center p-[3%] h-[27%] text-xl font-bold text-white font-inter hover:bg-[rgba(1,1,1,0.45)] text-left`}
                  >
                    <div className=" flex flex-row justify-center  items-center text-left">
                      <img
                        className="w-7 h-7 rounded-full "
                        src={item.uri}
                        alt="eth"
                      />
                      <div className="ml-[12px] flex flex-col">
                        <div className="text-sm ">{item.symbol}</div>
                        <div className="font-inter text-sm text-[rgba(255,255,255,0.8)]">
                          {item.symbol}
                        </div>
                      </div>
                    </div>
                    <div className=" flex flex-col text-left w-1/3">
                      <div className="font-inter text-sm text-[rgba(255,255,255,0.8)]">
                        {item?.balance ? (item?.balance).toFixed(2) : 0}
                      </div>
                      {dollarPrice !== 0 && (
                        <div className="font-inter text-sm text-[rgba(255,255,255,0.8)]">
                          $ {dollarPrice && dollarPrice?.toFixed(2)}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  const UnStakeChainAndTokenListView = () => {
    return (
      <div className="fixed inset-0  backdrop-blur-xl flex justify-center items-center bg-[rgba(0,0,0,0.3)] h-[100%] w-[100%] overflow-y-auto">
        <div
          id="wrapper"
          className=" font-inter flex flex-col  text-center  p-[32px]  rounded-[2.25rem] justify-center align-middle w-[34rem] h-auto   bg-[rgba(255,255,255,0.06)]  backdrop-blur-[36px] shadow-[0rem_2.5rem_2rem_-3.313rem_rgba(0,0,0,0.06)]   mt-[5.48rem]  border-[0.125rem] border-[rgba(255,255,255,0.2)] "
        >
          <div className="flex flex-row justify-between align-middle">
            <div className="text-white font-inter text-2xl font-bold">
              UnStake From
            </div>
            <img
              className="cursor-pointer"
              src={close}
              alt="close"
              onClick={() => {
                updateIsUnstakeTokenListVisible(false);
              }}
            />
          </div>
          <div className="flex flex-row justify-start align-middle py-3"></div>
          <div className="w-[100%] h-[288px] bg-[rgba(255,255,255,0.05)] rounded-3xl overflow-y-auto no-scrollbar  mt-2 ">
            {suiUnstakeTokenList &&
              suiUnstakeTokenList?.map((item: any, key: any) => {
                let dollarPrice = 0;
                dollarPrice = Number.isNaN(dollarPrice) || 0 ? 0 : dollarPrice;
                return (
                  <div
                    key={key}
                    onClick={() => updateUnstakeTokenSelect(item)}
                    className={`cursor-pointer flex flex-row justify-between align-middle items-center p-[3%] h-[27%] text-xl font-bold text-white font-inter hover:bg-[rgba(1,1,1,0.45)] text-left`}
                  >
                    <div className=" flex flex-row justify-center  items-center text-left">
                      <img
                        className="w-7 h-7 rounded-full "
                        src={item.uri}
                        alt="eth"
                      />
                      <div className="ml-[12px] flex flex-col">
                        <div className="text-sm ">{item.symbol}</div>
                        <div className="font-inter text-sm text-[rgba(255,255,255,0.8)]">
                          {item.symbol}
                        </div>
                      </div>
                    </div>
                    <div className=" flex flex-col text-left w-1/3">
                      <div className="font-inter text-sm text-[rgba(255,255,255,0.8)]">
                        {item?.balance ? (item?.balance).toFixed(2) : 0}
                      </div>
                      {dollarPrice !== 0 && (
                        <div className="font-inter text-sm text-[rgba(255,255,255,0.8)]">
                          $ {dollarPrice && dollarPrice?.toFixed(2)}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
  const Faucet = () => {
    const handleClick = async (token: any) => {
      if (isConnected) {
        try {
          const data = await submitFaucetPayload(
            token,
            signAndExecuteTransactionBlock
          );
          notification.success({
            message: `Faucet successfully.`,
            description: (
              <div>
                <p>
                  <a
                    className="text-purple-700 underline hover:underline hover:text-purple-500"
                    href={`https://suiexplorer.com/txblock/${data?.digest}?network=testnet`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View transaction
                  </a>
                </p>
              </div>
            ),
          });
        } catch (error: any) {
          if (
            error.toString().includes("Error: Rejected from user") ||
            error.toString().includes("Error: User Rejected the request")
          ) {
            notification.warning({
              message: `Wallet Error`,
              description: `User Rejected The Request `,
            });
          } else {
            console.log("Stake.tsx:288 ~ handleClick ~ error:", error);
          }
        }
      }
    };
    const items = [
      { key: "1", label: "usdc", logo: SUI_TOKEN_2.logoURI },
      { key: "2", label: "usdt", logo: SUI_TOKEN_3.logoURI },
    ];
    return (
      <div className="fixed top-4 right-4 p-4 z-50">
        <Dropdown
          menu={{
            items: items.map((item) => ({
              key: item.key,
              label: (
                <div
                  className="flex items-center"
                  onClick={() => handleClick(item.label)}
                >
                  <img
                    src={item.logo}
                    alt={item.label}
                    className="w-5 h-5 rounded-full mt-2 mr-2"
                  />
                  <span>{item.label}</span>
                </div>
              ),
            })),
          }}
          placement="bottomLeft"
          arrow
        >
          <Button className="text-white opacity-88">Faucet</Button>
        </Dropdown>
      </div>
    );
  };
  const HeaderText = () => {
    return (
      <div className="fixed top-1 p-1 text-center text-[#6b6b6c] z-50">
        {
          "*Currently running on the testnet, please note that expected and actual results may vary."
        }
      </div>
    );
  };
  return (
    <div className="ml-[11%] font-inter bg-[#0C0C0D] w-full h-screen flex flex-row justify-center items-center">
      {contextHolder}
      {!activeItem ? (
        <div className="p-[24px] w-[530px] h-auto shadow-custom rounded-[16px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54px]">
          <div className="text-[14px] gap-[8px] font-[400] text-[rgba(255,255,255,0.80)] flex flex-row justify-start items-center">
            {t("choose_your_token")}
            <img src={Info} alt="Info" />
          </div>
          <div className="flex flex-row justify-between items-center mt-[8px] mb-[16px] gap-[16px]">
            <div
              className="cursor-pointer text-[white] flex-[0.5] gap-[8px] flex flex-row justify-start items-center shadow-box_shadow  rounded-[16px] px-[24px] py-[16px] border-[rgba(245,247,250,0.06)] border-[1px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)]"
              onClick={() => {
                updateIsTokenListVisible(true);
              }}
            >
              <div className="flex flex-row justify-start items-end">
                <img
                  src={sourceInfo.tokenImg}
                  alt="sui"
                  className="w-10 h-10 p-[2px] rounded-full"
                />
                <img
                  src={SUI_TOKEN_1.logoURI}
                  alt="Sui"
                  className="ml-[-16px] w-4 h-4"
                />
              </div>
              <div className="text-[20px] font-[700] flex flex-col justify-start items-start">
                {sourceInfo.tokenSymbol}
                <div className="text-[10px] font-[400]">
                  on <b>Sui</b>
                </div>
              </div>
            </div>
            <div>
              <img src={DoubleArrow} alt="Double_arrow" />
            </div>
            <div className="cursor-not-allowed text-[white] flex-[0.5] gap-[8px] flex flex-row justify-start items-center shadow-box_shadow   rounded-[16px] px-[24px] py-[16px] border-[rgba(245,247,250,0.06)] border-[1px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)]">
              <div className="flex flex-row justify-start items-end">
                <img
                  src={SUI_TOKEN_1.logoURI}
                  alt="sui"
                  className="w-10 h-10 p-[2px] rounded-full"
                />
                <img
                  src={SUI_TOKEN_1.logoURI}
                  alt="Sui"
                  className="ml-[-16px] w-4 h-4"
                />
              </div>
              <div className="text-[20px] font-[700] flex flex-col justify-start items-start">
                Staked SUI
                <div className="text-[10px] font-[400]">
                  on <b>Sui</b>
                </div>
              </div>
            </div>
          </div>
          <div className="text-[14px] gap-[8px] font-[400] text-[rgba(255,255,255,0.80)] flex flex-row justify-start items-center">
            {t("enter_amount_of_token")}
            <img src={Info} alt="Info" />
          </div>
          <div className="flex flex-row justify-start items-center w-full">
            <input
              id="token-amount"
              className="placeholder:text-[rgba(255,255,255,0.60)]   text-[white] text-[20px] font-[500] flex flex-row justify-end w-full outline-none text-right items-center mt-[8px] mb-[16px] rounded-[16px] p-[24px] pr-[55px] border-[1px] border-[rgba(255,255,255,0.10)] bg-[#111213]"
              type="text"
              value={sourceInfo.tokenAmount}
              placeholder="0"
              min="0"
              autoFocus={true}
              autoComplete="off"
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              onPaste={preventPasteNegativeNumber}
              onChange={onTokenAmountChange}
            />
            <img
              src={Cancel}
              alt="Cancel"
              className="ml-[-40px] mb-2 cursor-pointer"
              onClick={() => {
                clearAmount("Clear");
              }}
            />
          </div>
          <div className="text-[14px] gap-[8px] font-[400] text-[rgba(255,255,255,0.80)] flex flex-row justify-start items-center">
            {t("protocols_and_split_ups")}
            <Tooltip
              placement="top"
              title={t("protocols_and_split_upsToolTip")}
              arrow={false}
              color={`${"#364152"}`}
              key={`${"#364152"}`}
            >
              <img src={Info} alt="Info" />
            </Tooltip>
          </div>
          <div className="flex flex-col justify-start w-full  items-center mt-[8px] mb-[16px] rounded-[16px] p-[16px] gap-[16px] border-[1px] border-[rgba(255,255,255,0.10)] overflow-auto no-scrollbar  max-h-[14rem]">
            {availableRoutes.length > 0 ? (
              (availableRoutes || []).map((route: any, index: any) => {
                return (
                  <div
                    className={`flex flex-col justify-start w-full  items-center mt-[8px] mb-[16px] rounded-[16px] p-[12px] gap-[14px] border-[1px]   bg-[#1D1E20] cursor-pointer ${selectedRoute.index === index
                      ? "border-[#0E9384]"
                      : "border-[rgba(255,255,255,0.10)]"
                      }   `}
                    onClick={() => setSelectedRoute(route)}
                    key={index}
                  >
                    <div className="border-[1px] border-b-[#202939] border-transparent text-[12px]  flex flex-row justify-between items-center w-full pb-[12px]">
                      <div className="text-[#0E9384] font-[400]  flex flex-row justify-start items-center gap-[8px]">
                        <div>{t("average_api")}</div>
                        <div className="font-[700]">
                          {route.isExpectedReturn.toFixed(2)}%
                        </div>
                      </div>
                      <div className="text-[#697586] font-[700]">
                        {(route.isExpectedReturnType).toUpperCase()}
                      </div>
                    </div>
                    <div className="flex flex-row justify-start items-center w-full flex-[1]">
                      <div className="flex-[0.5] text-[14px] font-[400] flex flex-row justify-start items-center gap-[8px]">
                        <img
                          src={route.isFirstPlatformLogo}
                          alt="Token"
                          className="w-4 h-4"
                        />
                        <div className="text-[#BBBBBC]">
                          {route.isFirstPlatform}
                        </div>
                        <div className="text-[#777879]">
                          {route.isFirstPlatformSplitUp}%
                        </div>
                      </div>
                      <div className="flex-[0.46] flex flex-row justify-start items-center  text-[white] text-[12px] font-[400]">
                        <div className="flex flex-row justify-start  items-center gap-[4px] flex-[0.45]">
                          <img
                            src={SUI_TOKEN_1.logoURI}
                            alt="Token"
                            className="w-4 h-4"
                          />
                          <div>{route.isFirstPlatformStakeAmount}</div>
                          <div className="text-[#777879]">
                            {SUI_TOKEN_1.symbol}
                          </div>
                        </div>
                        <div className="mx-1">
                          <img src={Arrow} alt="Arrow"  className="mx-2" />
                        </div>
                        <div className="flex flex-row justify-start  items-center gap-[4px] flex-[0.45] ">
                          <img
                            src={route.isFirstPlatformLogo}
                            alt="Token"
                            className="w-4 h-4"
                          />
                          <div>{route.isFirstPlatformStakeAmount}</div>
                          <div className="text-[#777879]">
                            {route.isFirstPlatformSymbol}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-start items-center w-full flex-[1]">
                      <div className="text-[14px] font-[400] flex flex-row justify-start items-center gap-[8px] flex-[0.5]">
                        <img
                          src={route.isSecandPlatformLogo}
                          alt="Token"
                          className="w-4 h-4"
                        />
                        <div className="text-[#BBBBBC]">
                          {route.isSecandPlatform}
                        </div>
                        <div className="text-[#777879]">
                          {route.isSecandPlatformSplitUp}%
                        </div>
                      </div>
                      <div className="flex flex-row justify-start items-center  text-[white] text-[12px] font-[400] flex-[0.5]">
                        <div className="flex flex-row justify-start items-center gap-[4px] flex-[0.45]">
                          <img
                            src={SUI_TOKEN_1.logoURI}
                            alt="Token"
                            className="w-4 h-4"
                          />
                          <div>{route.isSecandPlatformStakeAmount}</div>
                          <div className="text-[#777879]">
                            {SUI_TOKEN_1.symbol}
                          </div>
                        </div>
                        <div className="mx-1">
                          <img src={Arrow} alt="Arrow" className="mx-2" />
                        </div>
                        <div className="flex flex-row justify-start  items-center gap-[4px] flex-[0.45]">
                          <img
                            src={route.isSecandPlatformLogo}
                            alt="Token"
                            className="w-4 h-4"
                          />
                          <div>{route.isSecandPlatformRewardAmount}</div>
                          <div className="text-[#777879]">{route.isSecandPlatformSymbol}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className=" text-[#474749]">
                {"Please Enter Amount To View Routes"}
              </div>
            )}
          </div>
          {availableRoutes.length > 0 ? (
            <div className="text-[14px] font-[500] text-[#0E9384] flex flex-row justify-between w-full  items-center my-[16px] rounded-[16px] py-[20px] px-[16px] gap-[16px] border-[1px] border-[rgba(255,255,255,0.10)] ">
              <div className="flex flex-row justify-start items-center gap-[8px] ml-8">
                <img
                  src={selectedRoute.isFirstPlatformLogo}
                  alt="coin"
                  className="ml-[-16px] w-4 h-4"
                />{" "}
                {`${selectedRoute.isFirstPlatform} (APY)`}
              </div>
              <div>{selectedRoute.isFirstPlatformApy}%</div>
              <div className="flex flex-row justify-start items-center gap-[8px]">
                <img
                  src={selectedRoute.isSecandPlatformLogo}
                  alt="coin"
                  className="ml-[-16px] w-4 h-4"
                />{" "}
                {`${selectedRoute.isSecandPlatform} (APY)`}
              </div>
              <div>{selectedRoute.isSecandPlatformApy}%</div>
            </div>
          ) : (
            <></>
          )}
          {isRouteLoading ? (
            <button className="text-[16px] font-[700] w-full text-center py-[16px] px-[24px] rounded-[16px] border-[1px]  flex flex-row justify-center items-center cursor-pointer   backdrop-blur-[54.36563491821289px] box_transparent border-transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow ">
              <Lottie
                loop
                animationData={kanaloaderred}
                play
                className="w-[4.5rem] h-[1.5rem] flex justify-center items-center"
              />
            </button>
          ) : isConnected &&
            sourceInfo.tokenAmount &&
            Number(sourceInfo.tokenAmount) > 0 ? (
            <button
              className="text-[16px] font-[700] w-full text-center py-[16px] px-[24px] rounded-[16px] border-[1px]  flex flex-row justify-center items-center cursor-pointer   backdrop-blur-[54.36563491821289px] box_transparent border-transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow "
              onClick={() => submitSwap()}
            >
              <>{t("stake")}</>
            </button>
          ) : isConnected &&
            (!sourceInfo.tokenAmount || Number(sourceInfo.tokenAmount) <= 0) ? (
            <button className="text-[16px] font-[700] w-full text-center py-[16px] px-[24px] rounded-[16px] border-[1px]  flex flex-row justify-center items-center cursor-pointer   backdrop-blur-[54.36563491821289px] box_transparent border-transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow ">
              <>{"Enter Stake Amount"}</>
            </button>
          ) : (
            <button
              className="text-[16px] font-[700] w-full text-center py-[16px] px-[24px] rounded-[16px] border-[1px]  flex flex-row justify-center items-center cursor-pointer   backdrop-blur-[54.36563491821289px] box_transparent border-transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow "
              onClick={() => {
                updateIsWalletConnectVisible(true);
              }}
            >
              <>{"Connect Wallets"}</>
            </button>
          )}
        </div>
      ) : (
        <div className="p-[24px] w-[530px] h-auto shadow-custom rounded-[16px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54px]">
          <div className="text-[14px] gap-[8px] font-[400] text-[rgba(255,255,255,0.80)] flex flex-row justify-start items-center">
            {t("choose_your_token")}
            <img src={Info} alt="Info" />
          </div>
          <div className="flex flex-row justify-between items-center mt-[8px] mb-[16px] gap-[16px]">
            <div
              className="cursor-pointer text-[white] flex-[0.5] gap-[8px] flex flex-row justify-start items-center shadow-box_shadow  rounded-[16px] px-[24px] py-[16px] border-[rgba(245,247,250,0.06)] border-[1px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)]"
              onClick={() => {
                updateIsUnstakeTokenListVisible(true);
              }}
            >
              <div className="flex flex-row justify-start items-end">
                <img
                  src={unStakesourceInfo.tokenImg}
                  alt="sui"
                  className="w-10 h-10 p-[2px] rounded-full"
                />
                <img
                  src={SUI_TOKEN_1.logoURI}
                  alt="Sui"
                  className="ml-[-16px] w-4 h-4"
                />
              </div>
              <div className="text-[20px] font-[700] flex flex-col justify-start items-start">
                {unStakesourceInfo.tokenSymbol}
                <div className="text-[10px] font-[400]">
                  on <b>Sui</b>
                </div>
              </div>
            </div>
            <div>
              <img src={DoubleArrow} alt="Double_arrow" />
            </div>
            <div className="cursor-not-allowed text-[white] flex-[0.5] gap-[8px] flex flex-row justify-start items-center shadow-box_shadow   rounded-[16px] px-[24px] py-[16px] border-[rgba(245,247,250,0.06)] border-[1px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)]">
              <div className="flex flex-row justify-start items-end">
                <img
                  src={SUI_TOKEN_1.logoURI}
                  alt="sui"
                  className="w-10 h-10 p-[2px] rounded-full"
                />
                <img
                  src={SUI_TOKEN_1.logoURI}
                  alt="Sui"
                  className="ml-[-16px] w-4 h-4"
                />
              </div>
              <div className="text-[20px] font-[700] flex flex-col justify-start items-start ">
                SUI
                <div className="text-[10px] font-[400]">
                  on <b>Sui</b>
                </div>
              </div>
            </div>
          </div>
          <div className="text-[14px] gap-[8px] font-[400] text-[rgba(255,255,255,0.80)] flex flex-row justify-start items-center">
            {t("enter_amount_of_token")}
            <img src={Info} alt="Info" />
          </div>
          <div className="flex flex-row justify-start items-center w-full">
            <input
              id="token-amount"
              className="placeholder:text-[rgba(255,255,255,0.60)]   text-[white] text-[20px] font-[500] flex flex-row justify-end w-full outline-none text-right items-center mt-[8px] mb-[16px] rounded-[16px] p-[24px] pr-[55px] border-[1px] border-[rgba(255,255,255,0.10)] bg-[#111213]"
              type="text"
              value={unStakesourceInfo.tokenAmount}
              placeholder="0"
              min="0"
              autoFocus={true}
              autoComplete="off"
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              onPaste={preventPasteNegativeNumber}
              onChange={unStakeonTokenAmountChange}
            />
            <img
              src={Cancel}
              alt="Cancel"
              className="ml-[-40px] mb-2 cursor-pointer"
              onClick={() => {
                unStakeClearAmount("Clear");
              }}
            />
          </div>
          <div className="text-[14px] font-[500] text-[#0E9384] flex flex-row justify-between w-full  items-center my-[16px] rounded-[16px] py-[20px] px-[16px] gap-[16px] border-[1px] border-[rgba(255,255,255,0.10)] ">
            <div className="flex flex-row justify-start items-center gap-[8px] ml-5">
              <img
                src={SUI_TOKEN_1.logoURI}
                alt="coin"
                className="ml-[-16px] w-4 h-4"
              />{" "}
              {`Expected Return`}
            </div>
            <div className="mr-5">
              {unStakeavailableRoutes ? unStakeavailableRoutes : 0} SUI
            </div>
          </div>

          {isRouteLoading ? (
            <button className="text-[16px] font-[700] w-full text-center py-[16px] px-[24px] rounded-[16px] border-[1px]  flex flex-row justify-center items-center cursor-pointer   backdrop-blur-[54.36563491821289px] box_transparent border-transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow ">
              <Lottie
                loop
                animationData={kanaloaderred}
                play
                className="w-[4.5rem] h-[1.5rem] flex justify-center items-center"
              />
            </button>
          ) : isConnected &&
            unStakesourceInfo.tokenAmount &&
            Number(unStakesourceInfo.tokenAmount) > 0 ? (
            <button
              className="text-[16px] font-[700] w-full text-center py-[16px] px-[24px] rounded-[16px] border-[1px]  flex flex-row justify-center items-center cursor-pointer   backdrop-blur-[54.36563491821289px] box_transparent border-transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow "
              onClick={() => submitUnstake()}
            >
              <>{t("unstake")}</>
            </button>
          ) : isConnected &&
            (!unStakesourceInfo.tokenAmount ||
              Number(unStakesourceInfo.tokenAmount) <= 0) ? (
            <button className="text-[16px] font-[700] w-full text-center py-[16px] px-[24px] rounded-[16px] border-[1px]  flex flex-row justify-center items-center cursor-pointer   backdrop-blur-[54.36563491821289px] box_transparent border-transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow ">
              <>{"Enter Stake Amount"}</>
            </button>
          ) : (
            <button
              className="text-[16px] font-[700] w-full text-center py-[16px] px-[24px] rounded-[16px] border-[1px]  flex flex-row justify-center items-center cursor-pointer   backdrop-blur-[54.36563491821289px] box_transparent border-transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow "
              onClick={() => {
                updateIsWalletConnectVisible(true);
              }}
            >
              <>{"Connect Wallets"}</>
            </button>
          )}
        </div>
      )}

      {isWalletConnectVisible && <ConnectWallet />}
      {isTokenListVisible && <ChainAndTokenListView />}
      {isUnstakeTokenListVisible && <UnStakeChainAndTokenListView />}
      {<Faucet />}
      {<HeaderText />}
    </div>
  );
};

export default Stake;
