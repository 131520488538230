import { useWalletKit } from "@mysten/wallet-kit";
import React  from "react";
import { useStore } from "store";
import close from "../assets/icons/close.svg";
const ConnectWallet: React.FC = () => {
  const {
    connect: suiConnect,
    wallets: suiWallets,
    disconnect: SuiDisconnect,
    isConnected,
  } = useWalletKit();
  const walletSelection = (item: any) => {
    if (!isConnected) {
      suiConnect(item.name);
    }
  };
  const disconnectWallet = () => {
    SuiDisconnect();
  };
  const { updateIsWalletConnectVisible } = useStore();
  return (
    <div className="fixed z-[2] inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[54px] flex flex-row justify-center 2xl:items-center xl:items-center lg:items-center md:items-center sm:items-center max-sm:items-center">
      <div
        className={`2xl:w-[35rem] xl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[21rem] max-sm:w-[21rem] h-[28.875rem] ${"bg-gradient-to-r from-[#f5fffa0f] to-[#F5F7FA00] border-[#1e1f22] backdrop-blur-[54.36563491821289px]"} border-2 rounded-[1rem]`}
      >
        <div className="flex justify-between p-[3%_5%] connect_bg_container rounded-t-[1rem]">
          <div className={`text-white font-[500] text-[1.25rem]`}>
            Connect Wallet
          </div>
          <img
            src={close}
            alt="close"
            className="cursor-pointer"
            onClick={() => {
              updateIsWalletConnectVisible(false);
            }}
          />
        </div>
        <div className="flex flex-col justify-center outline-none ">
          {suiWallets.length > 0 ? (
            suiWallets?.map((item: any, index: any) => (
              <div
                className={`flex flex-row items-center py-2 px-2 my-1 rounded-2xl cursor-pointer text-white hover:bg-widgetPrimary ml-5`} //${selected.tokenName === token.symbol && 'bg-widgetPrimary'}
                onClick={() => walletSelection(item)}
                key={index}
              >
                <img className="w-11" src={item.icon} alt="token" />
                <div className="font-inter font-bold leading-5 ml-3">
                  <div className="text-lg ">{item.name}</div>
                </div>
                {item.connected && (
                  <div
                    className="flex-1 text-right text-white mr-10"
                    onClick={() => disconnectWallet()}
                  >
                    Disconnect
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="text-center">No Wallet's Found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
