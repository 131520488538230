import React, { useEffect, useState } from "react";
import Kana from "../assets/icons/kana_logo.svg";
import Stake from "../assets/icons/stake_icon.svg";
import UserCircle from "../assets/icons/user-circle.svg";
import { useTranslation } from "react-i18next";
import { useStore } from "store";
import { useWalletKit } from "@mysten/wallet-kit";

const Sidebar = () => {
  const [currentWalletAddress, setCurrentWalletAddress] = useState("");
  const {
    updateIsWalletConnectVisible,
    setActiveItem,
  } = useStore();
  const {
    currentWallet,
    currentAccount,
    isConnected,
  } = useWalletKit();
  useEffect(() => {
    if (isConnected) {
      updateIsWalletConnectVisible(false);
      setCurrentWalletAddress(currentAccount?.address!);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  const [activeItemSidebar, setActiveItemSidebar] = useState(1);
  const menuItems = [
    // Define your menu items here
    { id: 1, label: "Stake", img: <img src={Stake} alt="Stake" /> },
    {
      id: 2,
      label: "Unstake",
      img: <img src={Stake} alt="unstake" />,
    },
  ];
  const handleItemClick = (id: any) => {
    setActiveItemSidebar(id)
    switch (id) {
      case 1:
        setActiveItem(false);
        break;
      case 2:
        setActiveItem(true);
        break;
      default:
        break;
    }
  };
  const { t } = useTranslation();

  return (
    <div className="font-inter w-[22%] bg-[#17181A] fixed h-screen p-[16px] flex flex-col justify-between items-start">
      <div className="w-full">
        <div className="px-[24px] py-[16px]  mb-[36px]">
          <img src={Kana} alt="Kana" />
        </div>
        {menuItems.map((item) => (
          <div
            key={item.id}
            className={`menu-item my-[4px] cursor-pointer w-full h-auto text-[white] text-[16px] font-[400] flex flex-row justify-start items-center py-[16px] px-[24px] rounded-[16px] hover:bg-[rgba(255,255,255,0.06)] hover:opacity-[1] hover:text-[white] hover:font-[700] gap-[16px] ${
              activeItemSidebar === item.id
                ? "font-[700] opacity-[1] bg-[rgba(255,255,255,0.06)]"
                : "opacity-[0.5]"
            }`}
            onClick={() => handleItemClick(item.id)}
          >
            {" "}
            {item.img}
            {item.label}
          </div>
        ))}
      </div>
      <div
        className="cursor-pointer bg-[#0C0C0D] text-[#2ED3B7]  text-[16px] text-[400] gap-[8px] rounded-[16px] p-[16px] w-full flex flex-row justify-start items-center"
        onClick={() => {
          updateIsWalletConnectVisible(true);
        }}
      >
        {isConnected ? (
          <>
            <img
              className="w-[1.3rem] h-[1.3rem] mr-1"
              src={currentWallet?.icon}
              alt="UserCircle"
            />
            {currentWalletAddress?.toString()?.slice(0, 8) +
              ".." +
              currentWalletAddress?.toString()?.slice(-8)}
          </>
        ) : (
          <>
            <img
              className="w-[1.3rem] h-[1.3rem] mr-1"
              src={UserCircle}
              alt="UserCircle"
            />
            {t("connect_wallet")}
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
