import { StateSlice, Store } from "store/types";

export type AppSlice = {
  isDarkMode: boolean;
  isWalletConnectVisible: boolean;
  isSelectedChain: number;
  web3AuthInfo: string;
  isTokenListVisible: boolean;
  isUnstakeTokenListVisible: boolean;
  activeItem: boolean;
  updateIsDarkMode(payload: boolean): void;
  updateIsWalletConnectVisible(payload: boolean): void;
  updateIsSelectedChain(payload: number): void;
  updateWeb3AuthInfo(payload: string): void;
  updateIsTokenListVisible(payload: boolean): void;
  updateIsUnstakeTokenListVisible(payload: boolean): void;
  setActiveItem(payload: boolean): void;
};

export const createAppSlice: StateSlice<Store, AppSlice> = (set) => ({
  isDarkMode: false,
  isWalletConnectVisible: false,
  isSelectedChain: 97,
  web3AuthInfo: "",
  isTokenListVisible: false,
  isUnstakeTokenListVisible: false,
  activeItem: false,
  updateIsDarkMode(payload: AppSlice["isDarkMode"]) {
    set({ isDarkMode: payload });
  },
  updateIsWalletConnectVisible(payload: AppSlice["isWalletConnectVisible"]) {
    set({ isWalletConnectVisible: payload });
  },
  updateIsSelectedChain(payload: AppSlice["isSelectedChain"]) {
    set({ isSelectedChain: payload });
  },
  updateWeb3AuthInfo(payload: AppSlice["web3AuthInfo"]) {
    set({ web3AuthInfo: payload });
  },
  updateIsTokenListVisible(payload: AppSlice["isTokenListVisible"]) {
    set({ isTokenListVisible: payload });
  },
  updateIsUnstakeTokenListVisible(
    payload: AppSlice["isUnstakeTokenListVisible"]
  ) {
    set({ isUnstakeTokenListVisible: payload });
  },
  setActiveItem(payload: AppSlice["activeItem"]) {
    set({ activeItem: payload });
  },
});
