export const SUI_TOKEN_1 = {
    address: '0x2::sui::SUI',
    decimals: 9,
    symbol: 'SUI',
    logoURI: 'https://assets-currency.kucoin.com/644b3314022eae0001db3110_coin-logo.png',
};

export const SUI_TOKEN_2 = {
    address: "0xb8656a09a489819f07c444cb4a4a61a3b482a5ea994fd71b0a643ffc1c2f2dd0::iusdc::IUSDC",
    decimals: 6,
    symbol: "USDC",
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png",
};

export const SUI_TOKEN_3 = {
    address: "0xb8656a09a489819f07c444cb4a4a61a3b482a5ea994fd71b0a643ffc1c2f2dd0::iusdt::IUSDT",
    decimals: 6,
    symbol: "USDT",
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
};

export const SUI_TOKEN_4 = {
    address: "0xac2afb455cbcdc2ff1a2e9bbb8aa4ccb4506a544b08c740886892a5cdf92f472::hasui::HASUI",
    decimals: 9,
    symbol: "HSUI",
    logoURI: "https://www.haedal.xyz/images/stsui.png",
};
