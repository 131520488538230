import { mintIusdc, mintIusdt } from "sui-stake";

export const submitSuiPayload = async (
  entryFunctionPayload: any,
  signAndExecuteTransactionBlock: any
) => {
  try {
    const txns: any = [];
    if (entryFunctionPayload.length === 2) {
      const hash = await signAndExecuteTransactionBlock({
        transactionBlock: entryFunctionPayload[0],
        options: { showEffects: true },
      });
      if (hash?.confirmedLocalExecution) {
        txns.push(hash);
      } else {
        console.log("Error occurred submitSuiPayload ::" + hash);
        throw new Error(`Error: ${hash?.confirmedLocalExecution}`);
      }
      const hash1 = await signAndExecuteTransactionBlock({
        transactionBlock: entryFunctionPayload[1],
        options: { showEffects: true },
      });
      if (hash1?.confirmedLocalExecution) {
        txns.push(hash1);
      } else {
        console.log("Error occurred submitSuiPayload ::" + hash1);
      }
    } else if (entryFunctionPayload.length === 1) {
      const hash = await signAndExecuteTransactionBlock({
        transactionBlock: entryFunctionPayload[0],
        options: { showEffects: true },
      });
      if (hash?.confirmedLocalExecution) {
        txns.push(hash);
      } else {
        console.log("Error occurred submitSuiPayload ::" + hash);
      }
    }
    return txns;
  } catch (error: any) {
    throw new Error(error.message ? error.message : error.toString());
  }
};

export const submitUnStakeSuiPayload = async (
  entryFunctionPayload: any,
  signAndExecuteTransactionBlock: any
) => {
  try {
    const txns: any = [];
    if (entryFunctionPayload) {
      const hash = await signAndExecuteTransactionBlock({
        transactionBlock: entryFunctionPayload,
        options: { showEffects: true },
      });
      if (hash?.confirmedLocalExecution) {
        txns.push(hash);
      } else {
        console.log("Error occurred submitSuiPayload ::" + hash);
      }
    }
    return txns;
  } catch (error: any) {
    throw new Error(error.message ? error.message : error.toString());
  }
};
export const submitFaucetPayload = async (
  token: any,
  signAndExecuteTransactionBlock: any
) => {
  try {
    if (token === "usdc") {
      const tx = await mintIusdc();
      const hash = await signAndExecuteTransactionBlock({
        transactionBlock: tx,
        options: { showEffects: true },
      });
      if (hash?.confirmedLocalExecution) {
        return hash;
      } else {
        console.log("Error occurred submitSuiPayload ::" + hash);
        throw new Error(`Error: ${hash?.confirmedLocalExecution}`);
      }
    } else if (token === "usdt") {
      const tx = await mintIusdt();
      const hash = await signAndExecuteTransactionBlock({
        transactionBlock: tx,
        options: { showEffects: true },
      });
      if (hash?.confirmedLocalExecution) {
        return hash;
      } else {
        console.log("Error occurred submitSuiPayload ::" + hash);
        throw new Error(`Error: ${hash?.confirmedLocalExecution}`);
      }
    }
  } catch (error: any) {
    throw new Error(error.message ? error.message : error.toString());
  }
};
