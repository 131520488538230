import {
  JsonRpcProvider,
  PaginatedCoins,
  testnetConnection,
} from "@mysten/sui.js";
const suiClient = new JsonRpcProvider(testnetConnection);
export async function getAllSuiTokenBalance(account: any, tokenList: any) {
  try {
    let res: any = await getOwnerCoinAssets(account);
    let amount = 0;
    if (res.length > 0) {
      let data: any = joinDuplicatesAndSumBalances(res);
      tokenList = tokenList.map((item: any) => {
        const tok = item.address;
        const obj = data.find((e: any) => e.coinAddress === tok);
        if (obj) {
          amount = getUiAmount(obj.balance, item.decimal);
          item["isAvailable"] = true;
          item["balance"] = amount;
        } else {
          item["isAvailable"] = false;
          item["balance"] = 0;
        }
        return item;
      });
      tokenList.sort((a: { balance: any }, b: { balance: any }) => {
        return b?.balance - a?.balance;
      });
      return { success: true, data: tokenList };
    } else {
      return { success: false, data: [] };
    }
  } catch (err) {
    //error log
  }
}

const getOwnerCoinAssets = async (
  signerAddress: string,
  coinType?: string | null
) => {
  const allCoinAsset: any[] = [];
  let nextCursor: string | null = null;
  while (true) {
    const allCoinObject: PaginatedCoins = await (coinType
      ? suiClient.getCoins({
          owner: signerAddress,
          coinType,
          cursor: nextCursor,
        })
      : suiClient.getAllCoins({
          owner: signerAddress,
          cursor: nextCursor,
        }));
    allCoinObject.data.forEach((coin: any) => {
      if (BigInt(coin.balance) > 0) {
        allCoinAsset.push({
          coinAddress: coin.coinType,
          coinObjectId: coin.coinObjectId,
          balance: coin.balance,
        });
      }
    });
    nextCursor = allCoinObject.nextCursor;

    if (!allCoinObject.hasNextPage) {
      break;
    }
  }
  return allCoinAsset;
};

export const joinDuplicatesAndSumBalances = (arr: any) => {
  const groupedData = arr.reduce((acc: any, obj: any) => {
    const { coinAddress, ...rest } = obj;
    if (!acc[coinAddress]) {
      acc[coinAddress] = { coinAddress, ...rest };
    } else {
      acc[coinAddress].balance = String(
        parseInt(acc[coinAddress].balance) + parseInt(obj.balance)
      );
    }
    return acc;
  }, {});

  const joinedData = Object.values(groupedData);
  return joinedData;
};

export function getUiAmount(number: number, tokenDecimal: number) {
  return number / Math.pow(10, tokenDecimal);
}


export const trimToFloor = (value: any, toFloor: number = 7) => {
  value = Math.floor(value * Math.pow(10, toFloor)) / Math.pow(10, toFloor);
  if (Math.abs(value) < 1.0) {
    let e = parseInt(value.toString().split("e-")[1]);
    if (e) {
      value *= Math.pow(10, e - 1);
      value = "0." + new Array(e).join("0") + value.toString().substring(2);
    }
  } else {
    let e = parseInt(value.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      value /= Math.pow(10, e);
      value += new Array(e + 1).join("0");
    }
  }
  return value;
};

export const preventPasteNegativeNumber = (e: any) => {
  const clipboardData = e.clipboardData || (window as any).clipboardData;
  const value = clipboardData.getData("text");
  if (!value || value.includes("-")) {
    e.preventDefault();
  } else {
    const pastedData = parseFloat(value);
    if (pastedData < 0) {
      e.preventDefault();
    }
  }
};




export const getSuiStructuredRoutes = async (routes: any, suiTokensList: any) => {
  let finalArr: any = [];
  (routes || []).map(async (route: any, index: any) => {
    let obj: any = {};
    obj.index = index;
    const FirstDecimal = suiTokensList?.find(
      (x: any) =>
        String(x.address)?.toLowerCase() ===
        String(route.stakeStratergy.stratergy[0].rewardToken)?.toLowerCase()
    );
    const SecandDecimal = suiTokensList?.find(
      (x: any) =>
        String(x.address)?.toLowerCase() ===
        String(route.stakeStratergy.stratergy[1].rewardToken)?.toLowerCase()
    );
    const data = sumPercentages(route.stakeStratergy.stratergy[0].splitUp,route.stakeStratergy.stratergy[0].apy,route.stakeStratergy.stratergy[1].splitUp,route.stakeStratergy.stratergy[1].apy)
    obj.route = route;
    obj.isFirstPlatformDecimal = FirstDecimal?.decimal;
    obj.isSecandPlatformDecimal= SecandDecimal?.decimal;
    obj.isFirstPlatform = route.stakeStratergy.stratergy[0].platform;
    obj.isSecandPlatform = route.stakeStratergy.stratergy[1].platform;
    obj.isFirstPlatformRewardToken = route.stakeStratergy.stratergy[0].rewardToken;
    obj.isSecandPlatformRewardToken = route.stakeStratergy.stratergy[1].rewardToken;
    obj.isFirstPlatformSplitUp = route.stakeStratergy.stratergy[0].splitUp;
    obj.isSecandPlatformSplitUp= route.stakeStratergy.stratergy[1].splitUp;
    obj.isFirstPlatformStakeAmount = getUiAmount(route.stakeStratergy.stratergy[0].stakeAmount,FirstDecimal?.decimal).toFixed(2);
    obj.isSecandPlatformStakeAmount= getUiAmount(route.stakeStratergy.stratergy[1].stakeAmount,SecandDecimal?.decimal).toFixed(2);
    obj.isFirstPlatformApy = route.stakeStratergy.stratergy[0].apy;
    obj.isSecandPlatformApy= route.stakeStratergy.stratergy[1].apy;
    obj.isFirstPlatformSymbol = FirstDecimal?.symbol;
    obj.isSecandPlatformSymbol= SecandDecimal?.symbol;
    obj.isFirstPlatformRewardAmount = getUiAmount(route.stakeStratergy.stratergy[0].rewardAmount,FirstDecimal?.decimal).toFixed(2);
    obj.isSecandPlatformRewardAmount= getUiAmount(route.stakeStratergy.stratergy[1].rewardAmount,SecandDecimal?.decimal).toFixed(2);
    obj.isFirstPlatformLogo =  FirstDecimal?.uri;
    obj.isSecandPlatformLogo=  SecandDecimal?.uri;
    obj.isExpectedReturn=  data;
    obj.isExpectedReturnType=  route.stakeStratergy.risk;
    finalArr.push(obj);
  });
  return finalArr;
};

function sumPercentages(percentage1A: number, percentage1B: number, percentage2A: number, percentage2B: number): number {
  const sum1 = ((percentage1A/100) * percentage1B);
  const sum2 = ((percentage2A/100) * percentage2B);
  const totalSum = sum1 + sum2;
  return totalSum;
}