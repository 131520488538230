import React from 'react';
import Sidebar from "components/Sidebar";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Stake from "components/Stake";
import { notification } from 'antd';
function App() {
  notification.config({
    placement: "bottomRight",
    maxCount: 1,
  });
  return (
    <>
      <Sidebar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Stake />} />
          <Route path="/unstake" element={<Stake />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
